<template>
  <!-- 内容 -->
  <div class="help_content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
<!--      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{ '帮助中心' }}</span></el-breadcrumb-item>-->
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{ lastPage=='lastPage'?'上一页':'帮助中心' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">公告中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="!isMobile" style="margin-top: 30px; display: flex; justify-content: space-between">
      <div style="display: inline-block; height: fit-content; width: 12%;">
        <div style="padding: 20px 0" v-for="(item, index) in noticeTypeList" @click="changeNoticeType(item)">
          <span :class="clickNum == item.id?'type_title_click': 'type_title'" style="display: inline-block; vertical-align: middle">{{item.name}}</span>
          <span v-if="item.systemCount > 0" style="border-radius: 25px; font-size: 12px; padding: 1px; background: #FF4D4F; color: #FFFFFF; width: 20px;text-align: center;display: inline-block;vertical-align: middle; margin-left: 5px">{{item.systemCount}}</span>
        </div>
      </div>
      <div class="notice_list" style="display: inline-block; width: 86%; margin-top: 23px;position: relative">
        <div style="display: flex; justify-content: space-between">
          <span style="display: inline-block; font-weight: 700;font-size: 28px;line-height: 24px;color: #464255;">{{title}}</span>
          <div style="display: inline-block; background: #F8FAFD;border: 1px solid #CCD2DA;border-radius: 4px;padding: 0px 10px;cursor: pointer" @click="setReadState">
            <span style="font-weight: 400;font-size: 14px;line-height: 24px;color: #464255;">全部已读</span>
          </div>
        </div>
        <div v-for="(item, i) in noticeMsgList" style="margin: 30px 0;cursor: pointer;text-align: left" @click="gotoNotice(item)">
          <div>
            <span style="display: inline-block">{{ item.title }}</span>
            <div v-if="item.state == 1" style="display: inline-block; margin-left: 10px; width: 8px;height: 8px;background: #FF4D4F; border-radius: 50%;vertical-align: top"></div>
          </div>
          <span style="display: inline-block;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; margin-top: 5px">{{ item.content }}</span>
          <span style="display: block !important;margin-top: 8px;color: #72777E;">{{ item.send_time }}</span>
        </div>
        <el-pagination
            class="paging"
            style="margin: 10px 0;position: relative; float: right"
            layout="prev, pager, next"
            @current-change="changePage"
            :page-size="10"
            :current-page="pageNum"
            :hide-on-single-page="capitalListTotal<=0"
            :total="capitalListTotal">
        </el-pagination>
      </div>
    </div>

    <div v-if="isMobile" style="margin-top: 20px">
      <el-collapse accordion v-model="clickNum" @change="change">
        <el-collapse-item class="mb_notic_list" v-for="(item, index) in noticeTypeList" style="margin-top: 10px" :name="item.id">
          <template slot="title">
<!--            一致性 Consistency<i class="header-icon el-icon-info"></i>-->
            <span :class="clickNum == item.id?'type_title_click': 'type_title'" style="display: inline-block; margin-left: 20px" :style="{color:clickNum == item.id?'#AB54DB !important':''}">{{item.name}}</span>
            <span v-if="item.systemCount>0" style="border-radius: 25px; font-size: 12px; padding: 1px; background: #FF4D4F; color: #FFFFFF; width: 20px; height: 12px; line-height: 12px; text-align: center;display: inline-block; margin-left: 5px">{{item.systemCount}}</span>
          </template>
          <div v-for="(item, i) in noticeMsgList" style="margin: 10px 0; padding: 0 15px; cursor: pointer;text-align: left" @click="gotoNotice(item)">
            <div>
              <span style="display: inline-block">{{ item.title }}</span>
              <div v-if="item.state == 1" style="display: inline-block; margin-left: 10px; width: 8px;height: 8px;background: #FF4D4F; border-radius: 50%;vertical-align: top"></div>
            </div>
<!--            <span style="display: inline-block;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; margin-top: 5px">{{ item.content }}</span>-->
            <span style="display: block !important;margin-top: 0px;color: #72777E;font-size: 12px">{{ item.send_time }}</span>
          </div>
          <el-pagination
              class="paging"
              style="margin: 10px 0;position: relative;text-align: center"
              layout="prev, pager, next"
              @current-change="changePage"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListTotal<=0"
              :total="capitalListTotal">
          </el-pagination>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import {getMyMsgCenten,setReadState,getUnreadMsgCount} from '../../server/message.js'
export default {
  name: "help_content_one",
  components: {

  },
  data(){
    return{
      title: '公告',
      clickNum: 7,
      noticeMsgList: [],
      index: '',
      lastPage: '',
      noticeTypeList: [{ id:7, name:'公告', systemCount: 0 }, { id:2, name:'交易通知', systemCount: 0 }, { id:6, name:'文章', systemCount: 0 },  { id:1, name:'系统通知', systemCount: 0 }],
      pageNum: 1,
      capitalListTotal: 10,
      isMobile: false,
    }
  },

  mounted() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
    this.index = this.$route.query.titleNum
    this.lastPage = this.$route.query.lastPage
    this.getUnreadMsgCount(7)
    this.getUnreadMsgCount2(2)
    this.getUnreadMsgCount3(6)
    this.getUnreadMsgCount4(1)
    this.getMyMsgCenten(7)
  },

  methods: {
    gotoNotice(item) {
      this.$router.push('helpNoticeContent?home=list'+'&noticeId=' + item.id + '&lastPage='+ this.lastPage)
    },

    repChar(msg) {
      var msg = msg.replace(/<\/?[^>]*>/g, ''); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ''); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ''); //去掉npsp
      return msg;
    },

    getMyMsgCenten(type){
      getMyMsgCenten(type, this.pageNum, 10, 10).then(res =>{
        if(!res) return
        if(res.success){
          if(res.data){
            this.capitalListTotal = res.data.totalRow
            // this.totalRow = res.data.totalRow
            if(res.data.list && res.data.list.length != 0){
              this.noticeMsgList = res.data.list
              for (var i = 0; i < this.noticeMsgList.length; i++) {
                this.noticeMsgList[i].content = this.repChar(this.noticeMsgList[i].content)
              }
            }
          }
        }else {
          this.$message.warning(res.message)
        }
      })
    },

    getUnreadMsgCount(type){
      getUnreadMsgCount(type, 10).then(res=>{
        console.log(res)
        if(!res) return
        if(res.success){
          this.noticeTypeList[0].systemCount = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getUnreadMsgCount2(type){
      getUnreadMsgCount(type, 10).then(res=>{
        console.log(res)
        if(!res) return
        if(res.success){
          this.noticeTypeList[1].systemCount = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getUnreadMsgCount3(type){
      getUnreadMsgCount(type, 10).then(res=>{
        console.log(res)
        if(!res) return
        if(res.success){
          this.noticeTypeList[2].systemCount = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getUnreadMsgCount4(type){
      getUnreadMsgCount(type, 10).then(res=>{
        console.log(res)
        if(!res) return
        if(res.success){
          this.noticeTypeList[3].systemCount = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    setReadState(){
      setReadState().then(res =>{
        if(!res) return
        if(res.success){
          this.getUnreadMsgCount(7)
          this.getUnreadMsgCount2(2)
          this.getUnreadMsgCount3(6)
          this.getUnreadMsgCount4(1)
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    changeNoticeType(item){
      this.clickNum = item.id
      this.title = item.name
      // if(item.id == 1){
      //   this.getMyMsgCenten(1)
      // } else if(item.id == 2){
      //   this.getMyMsgCenten(2)
      // }
      this.getMyMsgCenten(item.id)
    },

    changePage(num){
      console.log('num', num)
      this.pageNum = num
      if(this.clickNum == 1){
        this.getMyMsgCenten(1)
      } else if(this.clickNum == 2){
        this.getMyMsgCenten(2)
      }
    },

    change(e){
      this.clickNum = e
      if (!e) return
      this.getMyMsgCenten(e)
      // if(e == 1){
      //   this.getMyMsgCenten(1)
      // } else if(e == 2){
      //   this.getMyMsgCenten(2)
      // }
    },

    goback(){
      this.$router.go(-1)
    }
  },

  activated() {
    if(this.clickNum == 1){
      this.getMyMsgCenten(7)
      this.getUnreadMsgCount(7)
    } else if(this.clickNum == 2){
      this.getMyMsgCenten(2)
      this.getUnreadMsgCount2(2)
    }
  }
}
</script>

<style scoped>
span{
  color: #1E2329;
}

@media screen and (max-width: 900px){
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }
  .content_list {
    column-count: 1;
  }
  .content_list span{
    color: #464255;
    font-size: 14px;
  }
  .content_title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    display: block;
  }
  .auto_list{
    column-count: 3;
    text-align: center;
  }
  .notice_list{
    column-count: 1;
    text-align: center;
  }
  .notice_list span:nth-child(1){
    font-size: 14px;
  }
  .notice_list span:nth-child(2){
    font-size: 12px;
  }
  .el-container{
    display: block;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 90%;
    height: 230px;
    text-align: center;
  }

  .help_content_top_left{
    margin-top: 20px;
  }
  .help_content_bg{
    text-align: center;
  }
  .mb_notic_list >>>.el-collapse-item__header{
    border-radius: 4px;
  }
  .mb_notic_list >>>.el-collapse-item__wrap{
    border-bottom: unset;
  }
  .mb_notic_list >>>.el-collapse-item__content{
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 900px){
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    color: #464255;
    width: 30%;
  }

  .auto_list{
    column-count: 6;
    text-align: center;
  }

  .notice_list{
    /*column-count: 2;*/
    /*text-align: center;*/
  }

  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 14px;
  }

  .help_content {
    width: 1200px;
    min-height: 795px;
    margin: 50px auto 0;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 380px;
    height: 260px;
    margin-right: 30px;
  }

  .help_content_top_left{
    margin-left: 30px;
  }

  .help_content_bg{
    text-align: unset;
  }

  .type_title{
    cursor: pointer;
  }

  .type_title:hover{
    color: #AB54DB !important;
  }

  .type_title_click{
    color: #AB54DB;
    border-bottom: 1px #AB54DB solid;
  }
}
.paging >>>.el-pager li{
  background: transparent;
}
.paging >>>.btn-prev{
  background: transparent;
}
.paging >>>.btn-next{
  background: transparent;
}
</style>
